var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"hero",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('br'),_c('div',{staticClass:"name-selecter-parent"},[_c('div',{staticClass:"name-selecter",on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-btn',{attrs:{"color":"white","text":""}},[_vm._v(" "+_vm._s(_vm.selectedItem ? _vm.selectedItem.firstName + " " + _vm.selectedItem.lastName : "All")+" "),_c('v-icon',{class:' ico-point2'},[_vm._v(_vm._s(_vm.show ? "mdi-menu-up" : "mdi-menu-down"))])],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"select-list"},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.users.records),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){_vm.selectedItem = item;
                  _vm.show = !_vm.show;
                  _vm.model = item.id;}}},[_c('v-list-item-icon',[(!_vm.profilePicture)?_c('v-icon',[_vm._v("mdi-account")]):_c('v-avatar',{attrs:{"size":"42"}},[_c('v-img',{attrs:{"src":_vm.profilePicture}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.firstName + ' ' + item.lastName)}})],1)],1)}),1)],1)])],1),(_vm.selectedItem != null)?_c('v-list',{staticClass:"forlist"},[_c('v-list-item-group',_vm._l((_vm.todolists.records.filter((item) => {
              return item.assignedTo == _vm.selectedItem.id;
            })),function(i){return _c('v-list-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',[_c('to-do-edit',{attrs:{"formType":'markDone',"fromId":i.id}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('h2',[_vm._v(_vm._s(i.description))]),_c('br'),_vm._v(" Created at "+_vm._s(_vm._f("getFormattedYYYYMMDD")(i.createdAt))+" ")])],1)]}}],null,true)})}),1),_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-col',{staticClass:"text-right"},[_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"green","fab":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}],null,false,950420621),model:{value:(_vm.taskDialog),callback:function ($$v) {_vm.taskDialog=$$v},expression:"taskDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_c('h1',[_vm._v("Create To Do Item")])]),_c('v-card-text',[_c('to-do-edit',{attrs:{"assignedTo":_vm.selectedItem.id,"assignedToName":_vm.selectedItem.firstName + ' ' + _vm.selectedItem.lastName}})],1),_c('v-divider')],1)],1)],1):_vm._e()],1),(
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl
      )?_c('v-col',{staticClass:"absolute-center",attrs:{"cols":"12","md":"6"}},[_c('v-avatar',{attrs:{"size":"200","tile":""}},[_c('v-img',{attrs:{"src":require("../../assets/FairwageLogo.png")}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }